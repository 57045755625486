import { VuexModule, Module, Action, Mutation, getModule, MutationAction } from 'vuex-module-decorators'
import store from '@/store'
import { IAccount, ICredentials, IPartner } from '@/utils/types'
import { login } from '@/api/authService'
import Vue from 'vue'
import { PartnerModule } from './partners'
import { ReprintGroupModule } from './reprintGroups'
import { CannedNoteModule } from './cannedNotes'
import { CatalogModule } from './catalog'
import { CartGroupModule } from './cartGroups'
import { CannedEmailModule } from './cannedEmails'
import { SignalMessageModule } from './signalMessages'
import { ShippingOptionsModule } from './shippingOptions'
import { ArtModule } from './art' 

@Module({ dynamic: true, store, name: 'auth' })
class Auth extends VuexModule {
    public user: any = JSON.parse(localStorage.getItem('auth-user') ?? 'null')

    @Mutation
    private SET_STATE(user: any) {
        localStorage.setItem('auth-user', JSON.stringify(user))
        this.user = user
    }

    @Action
    public async login(credentials: ICredentials) {
        await login(credentials)
            .then(response => {
                this.SET_STATE(response.data)
                this.restoreContext()
            })
            .catch(err => {
                this.logout()
            })
    }

    @Action
    public async logout() {
        this.SET_STATE(null)
        Vue.prototype.$signalHub.stopSignalR()
    }

    @Action
    public async restoreContext() {
        if (this.user !== null) {
            await PartnerModule.getPartners()
            await PartnerModule.getStaplesStores()
            await ReprintGroupModule.getReprintGroups()
            await CannedNoteModule.getCannedNotes()
            await CannedEmailModule.getCannedEmails()
            await CartGroupModule.getCartGroups()
            await CatalogModule.getCatalog()
            await ShippingOptionsModule.getShippingOptions()
            await ArtModule.getTags()
            await ArtModule.getArtists()
            //await CatalogModule.getDesigns()
            //await CatalogModule.getProducts()
            //await CatalogModule.getPackages()
            //await CatalogModule.getFonts()
            Vue.prototype.$signalHub.startSignalR(`${this.user.jwtToken}`)
        } else {
            console.warn('Cannot restore not logged in')
        }
    }
}

export const AuthModule = getModule(Auth)
