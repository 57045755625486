import http from '@/api/http'

export const getPackages = () => http.get(`/catalog/packages`)

export const getDesigns = () => http.get(`/catalog/designs`)

export const getProducts = () => http.get(`/catalog/products`)

export const getFonts = () => http.get(`/catalog/fonts`)

export const getProduct = (productId: number) =>
    http({
        url: '/catalog/products/' + productId,
        method: 'get'
    })

export const getProductDesigns = (productId: number) =>
    http({
        url: '/catalog/products/' + productId + '/designs',
        method: 'get'
    })

export const refreshcatalog = () => http.get(`/catalog/refreshcatalog`)

export const getCatalog = () => http.get(`/catalog`)