import http from '@/api/http'
import qs from 'qs'
import { ICartShipment, IPagination, IShippingRate, IShippingOption, IShippingRateRequest } from '../utils/types'

//export const getShippingRates = (cartId: string, weight: number, deliveryOption?: number) => http.get(`/shipping/getshippingrates/${cartId}/?weight=${weight}`)
export const getShippingRates = (shippingRateRequest: IShippingRateRequest) => http.post(`/shipping/getshippingrates`, shippingRateRequest)

export const voidShippingLabel = (cartShipmentId: number) => http.put(`/shipping/voidshippinglabel`, JSON.stringify(cartShipmentId))

export const archiveShippingLabel = (cartShipmentId: number) => http.post(`/shipping/archiveshippinglabel`, cartShipmentId)

export const getCartShippingLabels = (cartIds: string[]) => http.post(`/shipping/getcartshippinglabels`, cartIds, { responseType: 'arraybuffer' })

//export const getShippingLabel = (id: number) => http.get(`/shipping/getshippinglabel/${id}`, { responseType: 'arraybuffer' })

//export const getCartShippingLabel = (cartShipmentId: number) => http.get(`/shipping/getshippinglabel/${cartShipmentId}`, { responseType: 'arraybuffer' })

export const buyShippingLabel = (params: IShippingRate) => http.post('/shipping/buyshippinglabel', params)

export const getShippingLabels = (params: IPagination, filters: any) => {
    const query = `${qs.stringify(params)}&${qs.stringify(filters, { arrayFormat: 'repeat' })}`
    return http.get(`/shipping/getcartshipments?${query}`)
}

export const getTrackingInfo = (trackingNumber: string) => http.get(`/shipping/gettrackinginfo/${trackingNumber}`)

export const getShippingHistory = (cartId: string) => http.get(`/shipping/getshippinghistory/${cartId}`)

export const getCartShippingOptions = (cartId: string, weight?: number, packageCode?: string) => http.get(`/shipping/getcartshippingoptions/${cartId}?weight=${weight}&packagecode=${packageCode}`)

export const updateCartShipment = (cartShipment: ICartShipment) => http.put('/shipping/updatecartshipment', cartShipment)

export const createCartShipment = (cartShipments: ICartShipment) => http.post('/shipping/createcartshipment', cartShipments)

export const getShippingOptions = () => http.get('/shipping/getshippingoptions')
