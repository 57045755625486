import http from '@/api/http'
import config from '@/config'
import { ICredentials } from '@/utils/types'
import axios from 'axios'

const loginHttp = axios.create({
    baseURL: config.$apiUrl,
    timeout: 45000,
    headers: {
        'Content-type': 'application/json'
    }
})

export const login = (credentials: ICredentials) => loginHttp.post('/auth/login', credentials)
export const forgotPassword = (emailAddress: string) => http.post('/auth/forgotpassword', JSON.stringify(emailAddress))
export const resetPassword = (credentials: ICredentials) => loginHttp.post('/auth/resetpassword', credentials)
