import http from '@/api/http'
import { ICartGroup, IPagination, IReprintGroup, IReprint } from '@/utils/types'
import qs from 'qs'

export const getReprintGroups = () => http({ url: '/reprints/groups', method: 'get' })

export const getReprintGroup = (id: number, params: IPagination) =>
    http({
        url: '/reprints/groups/' + id,
        method: 'get',
        params,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    })

export const updateReprintGroup = (reprintGroup: IReprintGroup) => http.put('/reprints/groups/' + reprintGroup.id, reprintGroup)

export const deleteReprintGroup = (reprintGroup: IReprintGroup) => http.delete('/reprints/groups/' + reprintGroup.id)

export const createReprintGroup = (reprintGroup: IReprintGroup) => http.post('/reprints/groups', reprintGroup)

export const deleteReprint = (reprint: IReprint) => http.delete('/reprints/' + reprint.id)

export const createReprint = (reprint: Partial<IReprint>) => http.post('/reprints/', reprint)

export const updateReprint = (reprint: IReprint) => http.put('/reprints', reprint)

export const getReprintArtwork = (reprintIds: Array<number>) => http.post('/reprints/createartwork', reprintIds, { responseType: 'arraybuffer' })
