import { VuexModule, Module, Action, Mutation, getModule, MutationAction } from 'vuex-module-decorators'
import store from '@/store'
import * as ArtService from '@/api/artService'
import { IArtist } from '../../utils/types'

@Module({ dynamic: true, store, name: 'artTags' })
class Art extends VuexModule {
    public colors: string[] = []
    public styles: string[] = []
    public subjects: string[] = []
    public artists: IArtist[] = []

    @Mutation
    private SET_COLORS(colors: string[]) {
        this.colors = colors
    }

    @Mutation
    private SET_STYLES(styles: string[]) {
        this.styles = styles
    }

    @Mutation
    private SET_SUBJECTS(subjects: string[]) {
        this.subjects = subjects
    }

    @Mutation
    private SET_ARTISTS(artists: IArtist[]) {
        this.artists = artists
    }

    @Action
    public async getTags() {
        const response = await ArtService.getTags()
        this.SET_COLORS(response.data.colors)
        this.SET_STYLES(response.data.styles)
        this.SET_SUBJECTS(response.data.subjects)
    }

    @Action
    public async getArtists() {
        const response = await ArtService.getArtists()
        this.SET_ARTISTS(response.data)
    }
}

export const ArtModule = getModule(Art)
