import * as Enums from '@/utils/enums';
import Countries from '@/utils/geo'
import { ICart } from '@/utils/types'
import Vue from 'vue'
import { BToast } from 'bootstrap-vue'
import { CartGroupModule } from '@/store/modules/cartGroups'
import config from '@/config'

Vue.mixin({
    data() {
        return {
            savedIndex: 0,
            apiUrl: config.$apiUrl
        }
    },
    computed: {},
    methods: {
        compareCarts(carts: ICart[], index: number) {
            if (carts[index] === undefined || carts[index - 1] === undefined) return false

            return (
                this.$moment
                    .utc(carts[index].paymentDateTime)
                    .local()
                    .day() !==
                this.$moment
                    .utc(carts[index - 1].paymentDateTime)
                    .local()
                    .day()
            )
        },
        cartGroup(cartGroupId: number) {
            const cartGroup = CartGroupModule.cartGroups.find(cg => cg.id === cartGroupId)
            if (cartGroup != null) return cartGroup
            return null
            //return { id: -1, color: '', name: '' }
        },
        formatCurrency(val: number, curr: Enums.Currencies) {
            return `$ ${val.toFixed(2)} (${Enums.Currencies[curr]})`
        },
        currency(curr: Enums.Currencies) {
            return Enums.Currencies[curr]
        },
        currencies() {
            const retValue = []
            for (const item in Enums.Currencies) {
                if (!isNaN(Number(item))) {
                    retValue.push({ key: item, value: Enums.Currencies[item] })
                }
            }
            return retValue
        },
        cartStatuses() {
            const retValue = []
            for (const item in Enums.CartStatuses) {
                if (!isNaN(Number(item))) {
                    retValue.push({ key: item, value: Enums.CartStatuses[item] })
                }
            }
            return retValue
        },
        cartStatus(val: number) {
            return Enums.CartStatuses[val]
        },
        svgCanvasTypes() {
            const retValue = []
            for (const item in Enums.SvgCanvasTypes) {
                if (!isNaN(Number(item))) {
                    retValue.push({ key: item, value: Enums.SvgCanvasTypes[item] })
                }
            }
            return retValue
        },
        svgCanvasType(val: number) {
            return Enums.SvgCanvasTypes[val]
        },
        accountCreditStatus(val: number) {
            return Enums.AccountCreditStatuses[val]
        },
        deliveryOptions() {
            const retValue = []
            for (const item in Enums.DeliveryOptions) {
                if (!isNaN(Number(item))) {
                    retValue.push({ key: item, value: Enums.DeliveryOptions[item] })
                }
            }
            return retValue
        },
        deliveryOption(val: number) {
            return Enums.DeliveryOptions[val]
        },
        paymentTypes() {
            const retValue = []
            for (const item in Enums.PaymentTypes) {
                if (!isNaN(Number(item))) {
                    retValue.push({ key: item, value: Enums.PaymentTypes[item] })
                }
            }
            return retValue
        },
        paymentType(val: number) {
            return Enums.PaymentTypes[val]
        },
        transactionType(val: number) {
            return Enums.TransactionTypes[val]
        },
        shipmentPackageTypes(val: number) {
            return Enums.ShipmentPackageTypes[val]
        },
        countries() {
            return Countries
        },
        country(val: string) {
            return Countries.find(c => c.code == val)
        },
        provinces(val: string) {
            return Countries.find(c => c.code === val)?.regions
        },
        cartShipmentStatus(val: number) {
            return Enums.CartShipmentStatuses[val]
        },
        artworkProcessStatus(val: number) {
            return Enums.ArtworkProcessStatuses[val]
        },
        messageTypes() {
            return Enums.MessageTypes
        },
        messageType(val: number) {
            if (val === null) return ''
            return Enums.MessageTypes[val]
        },
        productStatuses() {
            const retValue = []
            for (const item in Enums.ProductStatuses) {
                if (!isNaN(Number(item))) {
                    retValue.push({ key: item, value: Enums.ProductStatuses[item] })
                }
            }
            return retValue
        },
        cartVariantColor(cart: ICart) {
            if (cart.deliveryOption === Enums.DeliveryOptions.Express) return 'warning'
            if (cart.deliveryOption === Enums.DeliveryOptions.Overnight) return 'danger'
            if (cart.cartStatus === Enums.CartStatuses.Open) return 'success'
            if (cart.cartStatus === Enums.CartStatuses.Closed) return 'primary'
            if (cart.cartStatus === Enums.CartStatuses.Processing || cart.cartStatus === Enums.CartStatuses.OnHold) return 'secondary'
        },
        cartStatusVariantColor(cartStatus: Enums.CartStatuses) {
            if (cartStatus === Enums.CartStatuses.Open) return 'success'
            if (cartStatus === Enums.CartStatuses.Closed) return 'primary'
            if (cartStatus === Enums.CartStatuses.Processing || cartStatus === Enums.CartStatuses.OnHold) return 'secondary'
        },
        deliveryOptionVariantColor(deliveryOption: Enums.DeliveryOptions) {
            if (deliveryOption === Enums.DeliveryOptions.Regular) return 'light'
            if (deliveryOption === Enums.DeliveryOptions.Partner || deliveryOption === Enums.DeliveryOptions.Store) return 'info'
            if (deliveryOption === Enums.DeliveryOptions.Express || deliveryOption === Enums.DeliveryOptions.HasTracking) return 'warning'
            if (deliveryOption === Enums.DeliveryOptions.Overnight) return 'danger'
        },
        onCopySuccess(e: string) {
            console.log(e)
            new BToast().$bvToast.toast('Copied to clipboard', {
                variant: 'success',
                noCloseButton: true,
                solid: true
            })
            return false
        },
        onCopyError(e: string) {
            new BToast().$bvToast.toast('Copy to clipboard error', {
                variant: 'danger',
                noCloseButton: true,
                solid: true
            })
        },
        checkSelected(event: any, index: number, collection: any[]) {
            const savedIndex = this.savedIndex
            this.savedIndex = index

            // If this is the first pass or no shift key was
            // pressed, just bail.
            if (savedIndex === null || !event.shiftKey) return

            // Shift-clicked the same item :-/
            if (index === savedIndex) return

            const subset = collection.slice(Math.min(index, savedIndex), Math.max(index, savedIndex) + 1)

            subset.forEach(obj => {
                obj.checked = event.currentTarget.checked
            })
        },
        downloadBlob(filename: string, blob: Blob) {
            const nav = window.navigator as any;
            if (nav.msSaveOrOpenBlob) {
                nav.msSaveOrOpenBlob(blob, filename);
            } else {
                const a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        }
    }
})
