export enum Currencies {
    CAD = 0,
    USD = 1,
    GBP = 2,
}

export enum DeliveryOptions {
    Regular = 0,
    Express = 1,
    Overnight = 2,
    Store = 3,
    Partner = 4,
    Pickup = 5,
    HasTracking = 6,
    Prime = 7
}

export enum CartStatuses {
    Unknown = 0,
    Open = 1,
    Processing = 2,
    Closed = 3,
    Cancelled = 4,
    OnHold = 5,
    Returned = 6
}

export enum ProductStatuses {
    Discontinued = 0,
    Active = 1, Refund = 0,
    Payment = 1,
    Adjustment = 2,
    Fee = 3
}

export enum PaymentTypes {
    Unknown = 0,
    Instant = 1,
    CreditCard = 2,
    PayPal = 3,
    GiftCard = 4,
    Cheque = 5,
    Marketplace = 6,
    Debit = 7,
    Manual = 8,
    Partner = 9,
}

export enum TransactionTypes {
    Refund = 0,
    Payment = 1,
    Adjustment = 2,
    Fee = 3
} 


export enum CartShipmentStatuses {
    Cancelled = 0,
    Active = 1,
    Archived = 2,
}

export enum ShippingServiceProviders {
    ShipStation = 0,
    Zazzle = 1,
    ShipEngine = 2,
    Amazon = 3,
    Manual = 4
}

export enum ArtworkProcessStatuses {
    Created = 0,
    Running = 1,
    Completed = 2,
    Stopped = 3,
}

export enum MessageTypes {
    Success = 0,
    Info = 1,
    Warning = 2,
    Danger = 3,
}

export enum AccountCreditStatuses {
    New = 0,
    Used = 1,
    Pending = 2,
    Available = 3,
    Cancelled = 4,
}

export enum ShipmentPackageTypes {
    SM = 0,
    LG = 1,
    XL = 2,
    XXL = 3,
}

export enum SvgCanvasTypes {
    MyMoji = 0,
    CustomProduct = 1,
    PetMoji = 2,
}
