import { BToast } from 'bootstrap-vue'
import axios from 'axios'
import store from '@/store/index'
import config from '@/config'
import { AuthModule } from '@/store/modules/auth'
import router from '@/router'

const http = axios.create({
    baseURL: config.$apiUrl,
    timeout: config.timeoutDuration,
    // baseURL: 'https://localhost:5001/api',
    headers: {
        'Content-type': 'application/json'
    },
    // withCredentials: true // send cookies when cross-domain requests
})

console.log()

// Request interceptors
http.interceptors.request.use(
    req => {
        store.commit('loading', true)
        if (AuthModule.user !== null) req.headers!.Authorization = `Bearer ${AuthModule.user.jwtToken}`
        return req
    },
    error => {
        store.commit('loading', false)
        console.error(error)
        Promise.reject(error)
    }
)

http.interceptors.response.use(
    response => {
        store.commit('loading', false)
        return response
    },
    error => {
        store.commit('loading', false)
        if (error.response && error.response.status === 401) {
            const currentPath = encodeURIComponent(window.location.pathname + window.location.search);
            const loc = `/login?redirect=${currentPath}`
            router.push({ path: loc })
        }

        if (error.response && error.response.status === 524) {
            alert('Cloudflare Response Timeout Occured.\r\n The server did not respond in the 100 seconds allowed.')
        }

        new BToast().$bvToast.toast(error.response.data, {
            title: 'Server Error',
            variant: 'danger',
            solid: true
        })

        return Promise.reject(error)
    }
)

export default http
