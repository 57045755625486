
    import Vue from 'vue'
    // import Layout from '@/views/layout.vue'
    import Component from 'vue-class-component'
    import { BToast, BModal, BIcon } from 'bootstrap-vue'
    import { IFont, ISignalRMessage } from '@/utils/types'
    import { AuthModule } from '@/store/modules/auth'
    import { Watch } from 'vue-property-decorator'
    import nProgress from 'nprogress'
    import 'nprogress/nprogress.css'
    import { CatalogModule } from '@/store/modules/catalog'
    import config from '@/config'

    @Component({
        name: 'App',
        components: {
            // Layout,
            BModal,
            BIcon
        }
    })
    export default class App extends Vue {
        created() {
            nProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })
            AuthModule.restoreContext()
            this.$signalHub.$off('message-received')
            this.$signalHub.$on('message-received', (message: ISignalRMessage) => {
                new BToast().$bvToast.toast(this.$createElement('div', { domProps: { innerHTML: message.text || '' } }), {
                    variant: this.messageType(message.type).toLowerCase(),
                    title: message.title || '',
                    solid: true
                })
            })

            const script = document.createElement('script')
            script.setAttribute('src', 'https://sandbox.web.squarecdn.com/v1/square.js')
            document.head.appendChild(script)
        }

        get fonts() {
            return CatalogModule.fonts
        }

        beforeDestroy() {
            this.$signalHub.stopSignalR()
        }

        @Watch('fonts')
        fontsChanged() {
            const fontStyle = document.createElement('style')

            this.fonts.forEach((font: IFont) => {
                fontStyle.appendChild(document.createTextNode(`@font-face { font-family: '${font.familyName}'; src: url("https://www.oliverslabels.com/content/fonts/${font.fileName}") format("truetype"); }`))
            })

            document.head.appendChild(fontStyle)
        }
    }
