let config: any

if (process.env.NODE_ENV === 'production') {
    config = {
        name: 'production',
        $apiUrl: 'https://adminv4api.oliverslabels.com/api',
        timeoutDuration: 600000,
        aquareSettings: {
            CAD: {
                appId: 'sq0idp-o72GSmRk75-lZHAjT9GkfQ',
                locationId: 'L8ZCHQBDA5SB9',
                apiKey: 'EAAAEJMNZ8cGP0pwUKr3v-gKO6ATghEMd3Y8JvkEPz7Pj6CVtF3_DQnq_AMVRzh9'
            },
            KidsLabelsCAD: {
                appId: 'sq0idp-Go1CQCEHxxhedkSg4j_Y_g',
                locationId: 'L9Q2E4KQEVHND',
                apiKey: 'EAAAEGLrRBZG254RlOFGllwSs7xfOmcpntldf2RTRiGMOIjlUfn7D5MSEnJ1zwsF'
            },
            LabelLilyCAD: {
                appId: 'sq0idp-BNLVed-WY7mT6UJHTi6Liw',
                locationId: 'LRH3JEVWVGWTY',
                apiKey: 'EAAAEMWwexHot96th6IJJXhsCiIAKIYi7Pl00WRIUYLgaILmTkNtNkPFrPjR6aFW'
            },
            USD: {
                appId: 'sq0idp-efdwINRAeqzzgyYJQ1atkQ',
                locationId: 'LS02JD0HVK7F8',
                apiKey: 'EAAAEP1ZAA7lG4mrdu8XfMb9ZJeLU1nTmkKUUXo_wxo-U3aNFFjpRXHBKAEr96Lw'
            },
            KidsLabelsUSD: {
                appId: 'sq0idp-cDWCFlww6kCpyJ_6a0tjBQ',
                locationId: 'LT472DSW5N9AH',
                apiKey: 'EAAAEKCyXEWYJwvKpbEBhFas5ovyXXSP0daJDtVB9F92j3yj_E38RSxhPsnEguP9'
            },
            LabelLilyUSD: {
                appId: 'sq0idp-K0lkqeinae_GL0GTggU_MQ',
                locationId: 'LCWF3PSQ0PWB8',
                apiKey: 'EAAAEYPOD4-M6-mM8EOi3wIUs2XxBmqHAgJvQkAhQUl6GrxbHjhKtNzuw7Rqwbfy'
            }
        }
    }
} else if (process.env.NODE_ENV === 'productionTo') {
    config = {
        name: 'productionTo',
        $apiUrl: 'https://adminv4to.oliverslabels.com/api'
    }
} else {
    config = {
        name: 'else',
         $apiUrl: 'http://localhost:63429/api',
        // $apiUrl: 'http://adminv4api:88/api',
        // $apiUrl: 'https://localhost:5001/api',
        // $apiUrl: 'https://adminv4apidev.oliverslabels.com:44342/api',
        //$apiUrl: 'https://adminv4api.oliverslabels.com/api',
        timeoutDuration: 600000,
        squareAppIdCa: 'sandbox-sq0idb-fjEl12ZOouDFxsdWgdEgDQ',
        squareAppIdUs: 'sandbox-sq0idb-ioP7z7jJuXqooUTliCt0Dw',
        squareSettings: {
            CAD: {
                appId: 'sandbox-sq0idb-fjEl12ZOouDFxsdWgdEgDQ',
                locationId: 'LN31FTVPWYQGY'
            },
            KidsLabelsCAD: {
                appId: 'sandbox-sq0idb-R9Yp6HTT8rJLCAe6XHwHiQ',
                locationId: 'LN31FTVPWYQGY'
            },
            LabelLilyCAD: {
                appId: 'sandbox-sq0idb-6njOqG7hv_ePwMSieGx6fQ',
                locationId: 'LN31FTVPWYQGY'
            },
            USD: {
                appId: 'sandbox-sq0idb-ioP7z7jJuXqooUTliCt0Dw',
                locationId: 'LR7ZZ9YWD2JV8'
            },
            KidsLabelsUSD: {
                appId: 'sandbox-sq0idb-GX1LYcEKv2QOrCL3VAKFug',
                locationId: 'LR7ZZ9YWD2JV8'
            },
            LabelLilyUSD: {
                appId: 'sandbox-sq0idb-oCVmlA61sPrbKFKflaH40A',
                locationId: 'LR7ZZ9YWD2JV8'
            }
        }
    }
}

export default config
