import { VuexModule, Module, Action, Mutation, getModule, MutationAction } from 'vuex-module-decorators'
import store from '@/store'
import { ICartGroup, ICatalog, IDesign, IPackage, IPagination, IProduct } from '@/utils/types'
import { getCartGroups } from '@/api/cartGroupService'

@Module({ dynamic: true, store, name: 'cartgroups' })
class CartGroups extends VuexModule {
    public cartGroups: ICartGroup[] = []

    @Mutation
    private SET_CARTGROUPS(cartGroups: ICartGroup[]) {
        this.cartGroups = cartGroups
    }

    @Action
    public async getCartGroups() {
        this.SET_CARTGROUPS((await getCartGroups()).data)
    }
}

export const CartGroupModule = getModule(CartGroups)
