import { VuexModule, Module, Action, Mutation, getModule, MutationAction } from 'vuex-module-decorators'
import store from '@/store'
import { ICartGroup, ICatalog, IDesign, IPackage, IPagination, IProduct, IReprintGroup } from '@/utils/types'
import { getReprintGroups } from '@/api/reprintsService'

@Module({ dynamic: true, store, name: 'reprintgroups' })
class ReprintGroups extends VuexModule {
    public reprintGroups: IReprintGroup[] = []

    @Mutation
    private SET_REPRINTGROUPS(reprintGroups: IReprintGroup[]) {
        this.reprintGroups = reprintGroups
    }

    @Action
    public async getReprintGroups() {
        this.SET_REPRINTGROUPS((await getReprintGroups()).data)
    }
}

export const ReprintGroupModule = getModule(ReprintGroups)
