import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { getCannedEmails } from '@/api/emailService'
import { ICannedEmail } from '@/utils/types'

@Module({ dynamic: true, store, name: 'emails' })
class CannedEmails extends VuexModule {
    public cannedEmails: ICannedEmail[] = []

    @Mutation
    private SET_CANNEDEMAILS(emails: ICannedEmail[]) {
        this.cannedEmails = emails
    }

    @Action
    public async getCannedEmails() {
        this.SET_CANNEDEMAILS((await getCannedEmails()).data)
    }
}

export const CannedEmailModule = getModule(CannedEmails)
