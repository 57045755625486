import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { AuthModule } from '@/store/modules/auth'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: () => import('@/views/_layout.vue'),
        meta: {
            // requiresAuth: true,
            isAdmin: true
        },
        children: [
            {
                path: '/',
                component: () => import('@/views/home.vue'),
                name: 'Home'
            },
            {
                path: '/carts',
                component: () => import('@/views/carts.vue'),
                name: 'Carts',
                meta: { title: 'Orders', icon: 'documentation', noCache: true }
            },
            {
                path: '/carts/:id',
                component: () => import('@/views/cart.vue'),
                name: 'EditCart',
                meta: {
                    title: 'Edit Order',
                    noCache: true,
                    hidden: true
                }
            },
            {
                path: '/svgcanvases',
                component: () => import('@/views/svgCanvases.vue'),
                name: 'Canvases',
                meta: { title: 'Canvases', icon: 'documentation', noCache: true }
            },
            {
                path: '/svgcanvases/:id',
                component: () => import('@/views/svgCanvas.vue'),
                name: 'EditCanvas',
                meta: {
                    title: 'Edit Canvas',
                    noCache: true,
                    hidden: true
                }
            },
            {
                path: '/cartgroups',
                component: () => import('@/views/cartGroups.vue'),
                name: 'ordergroups',
                meta: { title: 'Cart Groups', icon: 'documentation', noCache: true }
            },
            {
                path: '/reprintgroups',
                name: 'Reprint Groups',
                component: () => import('@/views/reprintGroups.vue')
            },
            {
                path: '/giftCards',
                name: 'GiftCards',
                component: () => import('@/views/giftCards.vue')
            },
            {
                path: '/cartShipments',
                name: 'CartShipments',
                component: () => import('@/views/cartShipments.vue')
            },
            {
                path: '/amazon/orders',
                name: 'Amazon',
                component: () => import('@/views/amazonOrders.vue')
            },
            {
                path: '/zazzle',
                name: 'Zazzle',
                component: () => import('@/views/zazzleOrders.vue')
            },
            {
                path: '/ppi',
                name: 'PPI Orders',
                component: () => import('@/views/ppiOrders.vue')
            },
            {
                path: '/ppipayments',
                name: 'PPI Payments',
                component: () => import('@/views/ppiPayments.vue')
            },
            {
                path: '/labeldaddy',
                name: 'Label Daddy Orders',
                component: () => import('@/views/labelDaddyOrders.vue')
            },
            {
                path: '/reprints/:id',
                name: 'Reprints',
                component: () => import('@/views/reprints.vue')
            },
            {
                path: '/workflow',
                name: 'workflow',
                component: () => import('@/views/workflow.vue')
            },
            {
                path: '/artworkprogress',
                name: 'Artwork Progress',
                component: () => import('@/views/artworkProgress.vue')
            },
            {
                path: '/production',
                name: 'Production',
                component: () => import('@/views/production.vue')
            },
            {
                path: '/fulfillment',
                name: 'Fulfillment',
                component: () => import('@/views/fulfillment.vue')
            },
            {
                path: '/reports',
                name: 'Reports',
                component: () => import('@/views/reports.vue')
            },
            {
                path: '/messages',
                name: 'Messages',
                component: () => import('@/views/signalMessages.vue')
            },
            {
                path: '/accounts',
                name: 'Accounts',
                component: () => import('@/views/accounts.vue')
            },
            {
                path: '/accounts/:siteId/:emailAddress',
                name: 'Account',
                component: () => import('@/views/account.vue')
            },
            {
                path: '/fundraisers',
                name: 'Fundraisers',
                component: () => import('@/views/fundraisers.vue')
            },
            {
                path: '/fundraisers/:id',
                name: 'Fundraiser',
                component: () => import('@/views/fundraiser.vue')
            },
            {
                path: '/promocodes',
                name: 'Promo Codes',
                component: () => import('@/views/promoCodes.vue')
            },
            {
                path: '/ppiproducts',
                name: 'PPI Products',
                component: () => import('@/views/ppiProducts.vue')
            },
            {
                path: '/ppiproducts/:id',
                name: 'PPI Product',
                component: () => import('@/views/ppiProduct.vue')
            },
            {
                path: '/arts',
                name: 'Art List',
                component: () => import('@/views/arts.vue')
            },
            {
                path: '/art/:id',
                name: 'Art',
                component: () => import('@/views/art.vue')
            },
            {
                path: '/amazonlistings',
                name: 'Amazon Listings',
                component: () => import('@/views/amazonListings.vue')
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login.vue'),
        meta: { hidden: true }
    },
    {
        path: '/passwordreset',
        name: 'PasswordReset',
        component: () => import('@/views/passwordReset.vue'),
        meta: { hidden: true }
    },
    {
        path: '/artworkProcess/:id',
        name: 'artworkprocess',
        component: () => import('@/views/artworkProcessMonitor.vue')
    },
    {
        path: '/businessproducts',
        name: 'businessproducts',
        component: () => import('@/views/businessProducts.vue')
    },
    {
        path: '/catalog',
        name: 'Catalog',
        component: () => import('@/views/catalog.vue')
    },
    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
