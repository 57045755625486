import http from '@/api/http'
import { IPartner, IPagination } from '@/utils/types'
import qs from 'qs'

export const getPartner = (id: number, params: IPagination) =>
    http({
        url: '/partners/' + id,
        method: 'get',
        params,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    })

export const getPartners = () => http.get('/partners')

export const updatePartner = (partner: IPartner) => http.put('/partners' + partner.id, partner)

export const deletePartner = (id: number) => http.delete('/partners/' + id)

export const getStaplesStores = () => http.get('/partners/getstaplesstores')
