import http from '@/api/http'
import { INote } from '@/utils/types'

export const getNotes = (referenceId: string) => http.get(`/notes/${referenceId}`)

export const createNote = (note: Partial<INote>) => http.post(`/notes`, JSON.stringify(note))

export const deleteNote = (noteId: number) => http.delete(`/notes/${noteId}`)

export const getCannedNotes = () => http.get('/notes/cannednotes')
