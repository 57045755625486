import { VuexModule, Module, Action, Mutation, getModule, MutationAction } from 'vuex-module-decorators'
import store from '@/store'
import { ISignalRMessage } from '@/utils/types'

@Module({ dynamic: true, store, name: 'signalMessages' })
class SignalMessages extends VuexModule {
    public signalMessages: ISignalRMessage[] = []

    @Mutation
    public ADD_MESSAGE(message: ISignalRMessage) {
        this.signalMessages.push(message)
    }

    @Mutation
    public CLEAR_MESSAGES() {
        this.signalMessages = []
    }

    // get messages() {
    //     return this.signalMessages
    // }
}

export const SignalMessageModule = getModule(SignalMessages)
