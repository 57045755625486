export default [
    { name: '---Select A Country---', code: null, regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    {
        name: 'United States',
        code: 'US',
        regionName: 'State',
        postalCodeName: 'Zip Code',
        regions: [
            { name: 'Select A State', code: null },
            { name: 'Alabama', code: 'AL' },
            { name: 'Alaska', code: 'AK' },
            { name: 'Arizona', code: 'AZ' },
            { name: 'Arkansas', code: 'AR' },
            { name: 'Armed Forces Americas', code: 'AA' },
            { name: 'Armed Forces Europe', code: 'AE' },
            { name: 'Armed Forces Pacific', code: 'AP' },
            { name: 'California', code: 'CA' },
            { name: 'Colorado', code: 'CO' },
            { name: 'Connecticut', code: 'CT' },
            { name: 'Delaware', code: 'DE' },
            { name: 'District of Columbia', code: 'DC' },
            { name: 'Florida', code: 'FL' },
            { name: 'Georgia', code: 'GA' },
            { name: 'Hawaii', code: 'HI' },
            { name: 'Idaho', code: 'ID' },
            { name: 'Illinois', code: 'IL' },
            { name: 'Indiana', code: 'IN' },
            { name: 'Iowa', code: 'IA' },
            { name: 'Kansas', code: 'KS' },
            { name: 'Kentucky', code: 'KY' },
            { name: 'Louisiana', code: 'LA' },
            { name: 'Maine', code: 'ME' },
            { name: 'Maryland', code: 'MD' },
            { name: 'Massachusetts', code: 'MA' },
            { name: 'Michigan', code: 'MI' },
            { name: 'Minnesota', code: 'MN' },
            { name: 'Mississippi', code: 'MS' },
            { name: 'Missouri', code: 'MO' },
            { name: 'Montana', code: 'MT' },
            { name: 'Nebraska', code: 'NE' },
            { name: 'Nevada', code: 'NV' },
            { name: 'New Hampshire', code: 'NH' },
            { name: 'New Jersey', code: 'NJ' },
            { name: 'New Mexico', code: 'NM' },
            { name: 'New York', code: 'NY' },
            { name: 'North Carolina', code: 'NC' },
            { name: 'North Dakota', code: 'ND' },
            { name: 'Ohio', code: 'OH' },
            { name: 'Oklahoma', code: 'OK' },
            { name: 'Oregon', code: 'OR' },
            { name: 'Pennsylvania', code: 'PA' },
            { name: 'Rhode Island', code: 'RI' },
            { name: 'South Carolina', code: 'SC' },
            { name: 'South Dakota', code: 'SD' },
            { name: 'Tennessee', code: 'TN' },
            { name: 'Texas', code: 'TX' },
            { name: 'Utah', code: 'UT' },
            { name: 'Vermont', code: 'VT' },
            { name: 'Virginia', code: 'VA' },
            { name: 'Washington', code: 'WA' },
            { name: 'West Virginia', code: 'WV' },
            { name: 'Wisconsin', code: 'WI' },
            { name: 'Wyoming', code: 'WY' }
        ]
    },
    {
        name: 'Canada',
        code: 'CA',
        regionName: 'Province',
        postalCodeName: 'Postal Code',
        regions: [
            { name: 'Select A Province', code: null },
            { name: 'Alberta', code: 'AB' },
            { name: 'British Columbia', code: 'BC' },
            { name: 'Manitoba', code: 'MB' },
            { name: 'New Brunswick', code: 'NB' },
            { name: 'Newfoundland and Labrador', code: 'NL' },
            { name: 'Northwest Territories', code: 'NT' },
            { name: 'Nova Scotia', code: 'NS' },
            { name: 'Nunavut', code: 'NU' },
            { name: 'Ontario', code: 'ON' },
            { name: 'Prince Edwards Island', code: 'PE' },
            { name: 'Quebec', code: 'QC' },
            { name: 'Saskatchewan', code: 'SK' },
            { name: 'Yukon', code: 'YT' }
        ]
    },
    { name: '-----------------------', code: null, regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Afghanistan', code: 'AF', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Aland Islands', code: 'AX', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Albania', code: 'AL', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Algeria', code: 'DZ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'American Samoa', code: 'AS', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Andorra', code: 'AD', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Angola', code: 'AO', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Anguilla', code: 'AI', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Antarctica', code: 'AQ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Antigua and Barbuda', code: 'AG', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Argentina', code: 'AR', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Armenia', code: 'AM', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Aruba', code: 'AW', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Australia', code: 'AU', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Austria', code: 'AT', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Azerbaijan', code: 'AZ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Bahamas', code: 'BS', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Bahrain', code: 'BH', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Bangladesh', code: 'BD', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Barbados', code: 'BB', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Belarus', code: 'BY', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Belgium', code: 'BE', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Belize', code: 'BZ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Benin', code: 'BJ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Bermuda', code: 'BM', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Bhutan', code: 'BT', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Bolivia', code: 'BO', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Bosnia and Herzegovina', code: 'BA', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Botswana', code: 'BW', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Bouvet Island', code: 'BV', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    {
        name: 'Brazil',
        code: 'BR',
        regionName: 'State',
        postalCodeName: 'Postal Code',
        regions: [
            { name: 'Acre', code: 'AC' },
            { name: 'Alagoas', code: 'AL' },
            { name: 'Amapá', code: 'AP' },
            { name: 'Amazonas', code: 'AM' },
            { name: 'Bahia', code: 'BA' },
            { name: 'Ceará', code: 'CE' },
            { name: 'Distrito Federal', code: 'DF' },
            { name: 'Espírito Santo', code: 'ES' },
            { name: 'Goiás', code: 'GO' },
            { name: 'Maranhão', code: 'MA' },
            { name: 'Mato Grosso', code: 'MT' },
            { name: 'Mato Grosso do Sul', code: 'MS' },
            { name: 'Minas Gerais', code: 'MG' },
            { name: 'Pará', code: 'PA' },
            { name: 'Paraíba', code: 'PB' },
            { name: 'Paraná', code: 'PR' },
            { name: 'Pernambuco', code: 'PE' },
            { name: 'Piauí', code: 'PI' },
            { name: 'Rio de Janeiro', code: 'RJ' },
            { name: 'Rio Grande do Norte', code: 'RN' },
            { name: 'Rio Grande do Sul', code: 'RS' },
            { name: 'Rondônia', code: 'RO' },
            { name: 'Roraima', code: 'RR' },
            { name: 'Santa Catarina', code: 'SC' },
            { name: 'São Paulo', code: 'SP' },
            { name: 'Sergipe', code: 'SE' },
            { name: 'Tocantins', code: 'TO' }
        ]
    },
    { name: 'British Indian Ocean Territory', code: 'IO', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Brunei Darussalam', code: 'BN', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Bulgaria', code: 'BG', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Burkina Faso', code: 'BF', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Burundi', code: 'BI', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Cambodia', code: 'KH', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Cameroon', code: 'CM', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Cape Verde', code: 'CV', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Cayman Islands', code: 'KY', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Central African Republic', code: 'CF', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Chad', code: 'TD', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    {
        name: 'Canada',
        code: 'CA',
        regionName: 'Province',
        postalCodeName: 'Postal Code',
        regions: [
            { name: 'Alberta', code: 'AB' },
            { name: 'British Columbia', code: 'BC' },
            { name: 'Manitoba', code: 'MB' },
            { name: 'New Brunswick', code: 'NB' },
            { name: 'Newfoundland and Labrador', code: 'NL' },
            { name: 'Northwest Territories', code: 'NT' },
            { name: 'Nova Scotia', code: 'NS' },
            { name: 'Nunavut', code: 'NU' },
            { name: 'Ontario', code: 'ON' },
            { name: 'Prince Edwards Island', code: 'PE' },
            { name: 'Quebec', code: 'QC' },
            { name: 'Saskatchewan', code: 'SK' },
            { name: 'Yukon', code: 'YT' }
        ]
    },
    { name: 'Chile', code: 'CL', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'China', code: 'CN', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Christmas Island', code: 'CX', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Cocos (Keeling) Islands', code: 'CC', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Colombia', code: 'CO', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Comoros', code: 'KM', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Congo', code: 'CG', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Congo, Democratic Republic of', code: 'CD', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Cook Islands', code: 'CK', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Costa Rica', code: 'CR', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Cote d’Ivoire', code: 'CI', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Croatia', code: 'HR', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Cuba', code: 'CU', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Cyprus', code: 'CY', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Czech Republic', code: 'CZ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Denmark', code: 'DK', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Djibouti', code: 'DJ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Dominica', code: 'DM', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Dominican Republic', code: 'DO', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'East Timor', code: 'TL', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Ecuador', code: 'EC', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Egypt', code: 'EG', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'El Salvador', code: 'SV', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Equatorial Guinea', code: 'GQ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Eritrea', code: 'ER', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Estonia', code: 'EE', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Ethiopia', code: 'ET', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Falkland Islands (Malvinas)', code: 'FK', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Faroe Islands', code: 'FO', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Fiji', code: 'FJ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Finland', code: 'FI', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'France', code: 'FR', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'French Guiana', code: 'GF', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'French Polynesia', code: 'PF', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'French Southern Territories', code: 'TF', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Gabon', code: 'GA', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Gambia', code: 'GM', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Georgia', code: 'GE', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Germany', code: 'DE', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Ghana', code: 'GH', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Gibraltar', code: 'GI', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Greece', code: 'GR', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Greenland', code: 'GL', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Grenada', code: 'GD', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Guadeloupe', code: 'GP', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Guam', code: 'GU', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Guatemala', code: 'GT', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Guinea', code: 'GN', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Guinea-Bissau', code: 'GW', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Guyana', code: 'GY', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Haiti', code: 'HT', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Heard and McDonald Islands', code: 'HM', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Honduras', code: 'HN', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Hong Kong', code: 'HK', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Hungary', code: 'HU', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Iceland', code: 'IS', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'India', code: 'IN', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Indonesia', code: 'ID', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Iran, Islamic Republic of', code: 'IR', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Iraq', code: 'IQ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Ireland', code: 'IE', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Israel', code: 'IL', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Italy', code: 'IT', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Jamaica', code: 'JM', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Japan', code: 'JP', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Jordan', code: 'JO', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Kazakhstan', code: 'KZ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Kenya', code: 'KE', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Kiribati', code: 'KI', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Korea, (South) Republic of', code: 'KR', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: "Korea, Democratic People's Republic", code: 'KP', regionName: 'Region', regions: [] },
    { name: 'Kuwait', code: 'KW', regionName: 'Region', regions: [] },
    { name: 'Kyrgyzstan', code: 'KG', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: "Lao People's Democratic Republic", code: 'LA', regionName: 'Region', regions: [] },
    { name: 'Latvia', code: 'LV', regionName: 'Region', regions: [] },
    { name: 'Lebanon', code: 'LB', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Lesotho', code: 'LS', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Liberia', code: 'LR', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Libya', code: 'LY', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Liechtenstein', code: 'LI', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Lithuania', code: 'LT', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Luxembourg', code: 'LU', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Macau', code: 'MO', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Macedonia, FYR', code: 'MK', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Madagascar', code: 'MG', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Malawi', code: 'MW', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Malaysia', code: 'MY', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Maldives', code: 'MV', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Mali', code: 'ML', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Malta', code: 'MT', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Marshall Islands', code: 'MH', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Martinique', code: 'MQ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Mauritania', code: 'MR', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Mauritius', code: 'MU', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Mayotte', code: 'YT', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    {
        name: 'Mexico',
        code: 'MX',
        regionName: 'State',
        postalCodeName: 'Postal Code',
        regions: [
            { name: ' Aguascalientes', code: 'Ags.' },
            { name: ' Baja California', code: 'B.C.' },
            { name: ' Baja California Sur', code: 'B.C.S.' },
            { name: ' Campeche', code: 'Camp.' },
            { name: ' Chiapas', code: 'Chis.' },
            { name: ' Chihuahua', code: 'Chih.' },
            { name: ' Coahuila', code: 'Coah.' },
            { name: ' Colima', code: 'Col.' },
            { name: ' Federal District (Mexico City)', code: 'D.F.' },
            { name: ' Durango', code: 'Dgo.' },
            { name: ' Guanajuato', code: 'Gto.' },
            { name: ' Guerrero', code: 'Gro.' },
            { name: ' Hidalgo', code: 'Hgo.' },
            { name: ' Jalisco', code: 'Jal.' },
            { name: ' México', code: 'Méx.' },
            { name: ' Michoacán', code: 'Mich.' },
            { name: ' Morelos', code: 'Mor.' },
            { name: ' Nayarit', code: 'Nay.' },
            { name: ' Nuevo León', code: 'N.L.' },
            { name: ' Oaxaca', code: 'Oax.' },
            { name: ' Puebla', code: 'Pue.' },
            { name: ' Querétaro', code: 'Qro.' },
            { name: ' Quintana Roo', code: 'Q. Roo.' },
            { name: ' San Luis Potosí', code: 'S.L.P.' },
            { name: ' Sinaloa', code: 'Sin.' },
            { name: ' Sonora', code: 'Son.' },
            { name: ' Tabasco', code: 'Tab.' },
            { name: ' Tamaulipas', code: 'Tamps.' },
            { name: ' Tlaxcala', code: 'Tlax.' },
            { name: ' Veracruz', code: 'Ver.' },
            { name: ' Yucatán', code: 'Yuc.' },
            { name: ' Zacatecas', code: 'Zac.' }
        ]
    },
    { name: 'Micronesia, Federal States of', code: 'FM', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Moldova', code: 'MD', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Monaco', code: 'MC', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Mongolia', code: 'MN', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Montenegro', code: 'CS', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Montserrat', code: 'MS', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Morocco', code: 'MA', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Mozambique', code: 'MZ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Myanmar', code: 'MM', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Namibia', code: 'NA', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Nauru', code: 'NR', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Nepal', code: 'NP', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Netherlands', code: 'NL', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Netherlands Antilles', code: 'AN', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'New Caledonia', code: 'NC', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'New Zealand', code: 'NZ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Nicaragua', code: 'NI', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Niger', code: 'NE', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Nigeria', code: 'NG', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Niue', code: 'NU', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Norfolk Island', code: 'NF', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Northern Mariana Islands', code: 'MP', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Norway', code: 'NO', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Oman', code: 'OM', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Pakistan', code: 'PK', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Palau', code: 'PW', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Palestinian Territory', code: 'PS', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Panama', code: 'PA', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Papua New Guinea', code: 'PG', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Paraguay', code: 'PY', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Peru', code: 'PE', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Philippines', code: 'PH', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Pitcairn', code: 'PN', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Poland', code: 'PL', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Portugal', code: 'PT', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Puerto Rico', code: 'PR', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Qatar', code: 'QA', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Reunion', code: 'RE', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Romania', code: 'RO', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Russian Federation', code: 'RU', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Rwanda', code: 'RW', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Saint Kitts and Nevis', code: 'KN', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Saint Lucia', code: 'LC', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Saint Vincent and The Grenadines', code: 'VC', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Samoa', code: 'WS', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'San Marino', code: 'SM', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Sao Tome and Principe', code: 'ST', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Saudi Arabia', code: 'SA', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Senegal', code: 'SN', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Serbia', code: 'RS', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Seychelles', code: 'SC', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Sierra Leone', code: 'SL', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Singapore', code: 'SG', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Slovakia', code: 'SK', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Slovenia', code: 'SI', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Solomon Islands', code: 'SB', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Somalia', code: 'SO', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'South Africa', code: 'ZA', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'South Georgia/South Sandwich Islands', code: 'GS', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'South Soudan', code: 'SS', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Spain', code: 'ES', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Sri Lanka', code: 'LK', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'St. Helena', code: 'SH', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'St. Pierre and Miquelon', code: 'PM', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Sudan', code: 'SD', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Suriname', code: 'SR', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Svalbard and Jan Mayen', code: 'SJ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Swaziland', code: 'SZ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Sweden', code: 'SE', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Switzerland', code: 'CH', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Syrian Arab Republic', code: 'SY', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Taiwan', code: 'TW', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Tajikistan', code: 'TJ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Tanzania', code: 'TZ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Thailand', code: 'TH', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Togo', code: 'TG', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Tokelau', code: 'TK', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Tonga', code: 'TO', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Trinidad and Tobago', code: 'TT', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Tunisia', code: 'TN', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Turkey', code: 'TR', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Turkmenistan', code: 'TM', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Turks and Caicos Islands', code: 'TC', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Tuvalu', code: 'TV', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Uganda', code: 'UG', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Ukraine', code: 'UA', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'United Arab Emirates', code: 'AE', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'United Kingdom', code: 'GB', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'United States Minor Outlying Islands', code: 'UM', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Uruguay', code: 'UY', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Uzbekistan', code: 'UZ', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Vanuatu', code: 'VU', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Vatican city (Holy See)', code: 'VA', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Venezuela', code: 'VE', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Viet Nam', code: 'VN', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Virgin Islands (British)', code: 'VG', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Virgin Islands (US)', code: 'VI', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Wallis and Futuna', code: 'WF', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Western Sahara', code: 'EH', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Yemen', code: 'YE', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Zambia', code: 'ZM', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] },
    { name: 'Zimbabwe', code: 'ZW', regionName: 'Region', postalCodeName: 'Postal Code', regions: [] }
]
