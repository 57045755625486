import http from '@/api/http'
import { IArt, IPagination } from '@/utils/types'
import qs from 'qs'

export const getArtList = (paginationDto: IPagination, filterText: string) => {
    const query = `${qs.stringify(paginationDto)}&filterText=${filterText}`
    return http.get(`/art?${query}`)
}

export const getTags = () => http.get(`/art/tags`)
export const getArtists = () => http.get(`/art/artists`)

export const getSuggestedTags = (imageUrl: string) => http.get(`/art/suggestions/tags?imageUrl=${imageUrl}`)

export const getSuggestedColors = (imageUrl: string) => http.get(`/art/suggestions/colors?imageUrl=${imageUrl}`)

export const getSuggestedDescription = (imageUrl: string, context: string) => http.post(`/art/suggestions/description?imageUrl=${imageUrl}`, context)

export const getArt = (id: number) => http.get(`/art/${id}`)
export function saveArt(art: IArt) {
    if (art.id > 0) {
        return http.put(`/art`, art) //update existing
    } else {
        return http.post(`/art`, art) //create new
    }
}

export const deleteArt = (id: number) => http.delete(`/art/${id}`)