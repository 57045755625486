import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { getCannedNotes } from '@/api/noteService'
import { INote } from '@/utils/types'

@Module({ dynamic: true, store, name: 'notes' })
class CannedNotes extends VuexModule {
    public cannedNotes: INote[] = []

    @Mutation
    private SET_CANNEDNOTES(notes: INote[]) {
        this.cannedNotes = notes
    }

    @Action
    public async getCannedNotes() {
        this.SET_CANNEDNOTES((await getCannedNotes()).data)
    }
}

export const CannedNoteModule = getModule(CannedNotes)
