import { VuexModule, Module, Action, Mutation, getModule, MutationAction } from 'vuex-module-decorators'
import store from '@/store'
import { ICatalog, IDesign, IPackage, IProduct, IFont } from '@/utils/types'
import { getDesigns, getPackages, getProducts, getFonts, getCatalog, refreshcatalog } from '@/api/catalogService'

@Module({ dynamic: true, store, name: 'catalog' })
class Catalog extends VuexModule implements ICatalog {
    public busy = false
    public products: IProduct[] = []
    public packages: IPackage[] = []
    public designs: IDesign[] = []
    public fonts: IFont[] = []

    @Mutation
    private SET_LOADING(busy: boolean) {
        this.busy = busy
    }

    @Mutation
    private SET_CATALOG(catalog: ICatalog) {
        this.products = catalog.products
        this.packages = catalog.packages
        this.designs = catalog.designs
        this.fonts = catalog.fonts
    }

    //@Mutation
    //private SET_PRODUCTS(products: IProduct[]) {
    //    this.products = products
    //}
    //@Mutation
    //private SET_PACKAGES(packages: IPackage[]) {
    //    this.packages = packages
    //}
    //@Mutation
    //private SET_DESIGNS(designs: IDesign[]) {
    //    this.designs = designs
    //}
    //@Mutation
    //private SET_FONTS(fonts: IFont[]) {
    //    this.fonts = fonts
    //}


    @Action
    public async getCatalog() {
        this.SET_LOADING(true)
        getCatalog().then(response => {
            this.SET_CATALOG(response.data)
        }).catch(err => {
            console.error("Failed to get catalog data", err)
        })
        .finally(() => {
            this.SET_LOADING(false)
        })
    }

    @Action
    public async refreshCatalog() {
        refreshcatalog().then(response => {
            this.getCatalog()
        }).catch(err => {
            console.error("Failed to refresh catalog data", err)
        })
    }

    //@Action
    //public async getProducts() {
    //    this.SET_LOADING(true)
    //    this.SET_PRODUCTS((await getProducts()).data)
    //    this.SET_LOADING(false)
    //}
    //@Action
    //public async getDesigns() {
    //    this.SET_LOADING(true)
    //    this.SET_DESIGNS((await getDesigns()).data)
    //    this.SET_LOADING(false)
    //}
    //@Action
    //public async getPackages() {
    //    this.SET_LOADING(true)
    //    this.SET_PACKAGES((await getPackages()).data)
    //    this.SET_LOADING(false)
    //}
    //@Action
    //public async getFonts() {
    //    this.SET_LOADING(true)
    //    this.SET_FONTS((await getFonts()).data)
    //    this.SET_LOADING(false)
    //}

}

export const CatalogModule = getModule(Catalog)
