import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import config from '@/config'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import '@/mixins/index'
import { } from '@/utils/types'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueClipboard from 'vue-clipboard2'
import '@/styles/index.scss'

import AsyncComputed from 'vue-async-computed'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import SignalHub from '@/api/signalHub'
import moment from 'vue-moment'

import { AxiosPlugin } from '@/plugins/axiosPlugin'
import { SignalHubPlugin } from '@/plugins/signalHubPlugin'
import CKEditor from 'ckeditor4-vue'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
// import { StripePlugin } from '@vue-stripe/vue-stripe'


for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
      ...validation
  })
}

const options = {
  pk: process.env.STRIPE_PUBLISHABLE_KEY,
  stripeAccount: process.env.STRIPE_ACCOUNT,
  apiVersion: process.env.API_VERSION,
  locale: process.env.LOCALE,
}

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(AsyncComputed)
Vue.use(VueClipboard)
Vue.use(AxiosPlugin)
Vue.use(SignalHubPlugin)
Vue.use(moment)
Vue.use(CKEditor)
//Vue.use(StripePlugin, options)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.prototype.appConfig = config

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
