/* eslint-disable */

import _Vue from 'vue'
import VueRouter from 'vue-router'
import { HubConnection, HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
import config from '@/config'
import { ISignalRMessage } from '@/utils/types'
import { SignalMessageModule } from '@/store/modules/signalMessages'

export function SignalHubPlugin(Vue: typeof _Vue): void {
    Vue.use(VueRouter)
    // do stuff with options
    const signalHub = new Vue()
    // Provide methods to connect/disconnect from the SignalR hub
    let connection: HubConnection
    let startedPromise: Promise<any> | null = null
    let manuallyClosed = false

    signalHub.startSignalR = (jwtToken: string | Promise<string>) => {
        connection = new HubConnectionBuilder()
            .withUrl(config.$apiUrl + '/hubs/signalhub', { accessTokenFactory: jwtToken ? () => jwtToken : () => '' })
            .configureLogging(LogLevel.Information)
            .build()


        // Forward hub events through the event, so we can listen for them in the Vue components
        connection.off('Message')
        connection.on('Message', (message: ISignalRMessage) => {
            SignalMessageModule.ADD_MESSAGE(message)
            signalHub.$emit('message-received', message)
        })

        connection.off('UpdateProcess')
        connection.on('UpdateProcess', process => {
            signalHub.$emit('process-updated', process)
        })

        connection.off('UpdateWorkflow')
        connection.on('UpdateWorkflow', hotFolder => {
            signalHub.$emit('workflow-updated', hotFolder)
        })

        connection.off('CartUpdated')
        connection.on('CartUpdated', cart => {
            signalHub.$emit('cart-updated', cart)
        })

        connection.off('CartGroupUpdated')
        connection.on('CartGroupUpdated', cart => {
            signalHub.$emit('cartgroup-updated', cart)
        })

        // You need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
        // Docs recommend listening onclose and handling it there.
        // This is the simplest of the strategies
        function start() {
            console.log('Attempting to starting SignalR...')
            startedPromise = connection.start().catch(err => {
                if (err.statusCode === 401) {
                    console.log('Unauthorized')
                    signalHub.stopSignalR()
                    Vue.prototype.$router.push('/login')
                }
                return new Promise((resolve, reject) =>
                    setTimeout(
                        () =>
                            start()
                                .then(resolve)
                                .catch(reject),
                        5000
                    )
                )
            })
            return startedPromise
        }
        connection.onclose(() => {
            if (!manuallyClosed) start()
        })

        // Start everything
        manuallyClosed = false

        start()
    }

    signalHub.stopSignalR = () => {
        console.log('Stopping SignalR...')
        if (!startedPromise) return

        manuallyClosed = true
        return startedPromise
            .then(() => connection.stop())
            .then(() => {
                startedPromise = null
            })
    }

    Vue.prototype.$signalHub = signalHub
}
