import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { getShippingOptions } from '@/api/shippingService'
import { IShippingOption } from '@/utils/types'

@Module({ dynamic: true, store, name: 'shippingOptions' })
class ShippingOptions extends VuexModule {
    public shippingOptions: IShippingOption[] = []

    @Mutation
    private SET_SHIPPINGOPTIONS(emails: IShippingOption[]) {
        this.shippingOptions = emails
    }

    @Action
    public async getShippingOptions() {
        this.SET_SHIPPINGOPTIONS((await getShippingOptions()).data)
    }
}

export const ShippingOptionsModule = getModule(ShippingOptions)
