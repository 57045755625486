import { VuexModule, Module, Action, Mutation, getModule, MutationAction } from 'vuex-module-decorators'
import store from '@/store'
import { IPartner, ISite, IStaplesStore } from '@/utils/types'
import { getPartners, getStaplesStores } from '@/api/partnerService'

@Module({ dynamic: true, store, name: 'partner' })
class Partners extends VuexModule {
    public partners: IPartner[] = []
    public staplesStores: IStaplesStore[] = []
    public sites: ISite[] = [
        { id: '', name: 'OliversLabels.com' },
        { id: 'kidslabels.com', name: 'KidsLabels.com' },
        { id: 'emilypress.com', name: 'EmilyPress.com' },
        { id: 'staplesbus', name: 'Staples Business' },
        { id: 'staplesca', name: 'Staples Co-branded' },
        { id: 'wayfair.ca', name: 'Wayfair.ca' },
        { id: 'wayfair.com', name: 'Wayfair.com' },
        { id: 'overstock.com', name: 'Overstock.com' }
    ]

    @Mutation
    private SET_PARTNERS(partners: IPartner[]) {
        this.partners = partners
    }

    @Mutation
    private SET_STAPLESSTORES(staplesStores: IStaplesStore[]) {
        this.staplesStores = staplesStores
    }

    @Action
    public async getPartners() {
        this.SET_PARTNERS((await getPartners()).data)
    }

    @Action
    async getStaplesStores() {
        this.SET_STAPLESSTORES((await getStaplesStores()).data)
    }
}

export const PartnerModule = getModule(Partners)
