import http from '@/api/http'
import { ICartGroup, IPagination } from '@/utils/types'
import qs from 'qs'

export const getCartGroups = (params?: IPagination) =>
    http({
        url: '/cartgroups',
        method: 'get',
        params,
        paramsSerializer: params => {
            return qs.stringify(params, { indices: false })
        }
    })
export const getCartGroup = (id: number, params: IPagination) =>
    http({
        url: '/cartgroups/' + id,
        method: 'get',
        params,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    })

export const createCartGroup = (cartGroup: Partial<ICartGroup>) => http.post('/cartgroups', cartGroup)

export const updateCartGroup = (cartGroup: ICartGroup) => http.put('/cartgroups', cartGroup)

export const deleteCartGroup = (id: number) => http.delete('/cartgroups/' + id)
